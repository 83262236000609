/**
 * Replaces whitespace characters in a `string` with hyphens.
 * @param {string} inputString - The `inputString` to format.
 * @returns {string} The formatted `string` with hyphens instead of whitespace.
 */
export const formatStringWithHyphens = (inputString: string): string => {
	return inputString.replace(/\s/g, '-')
}

/**
 * Formats an array of strings into a data test ID by replacing whitespace characters with hyphens and joining them with hyphens.
 * @param {string[]} strings - The array of strings to format.
 * @returns {string} The formatted data test ID.
 */
export const formatDataTestId = (strings: string[]): string => {
	return strings
		.filter((string) => string.length > 0)
		.map((string) => formatStringWithHyphens(string.toLowerCase()))
		.join('-')
}

import { ChangeEvent, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { debounce } from 'src/_shared/_old/utils/debounce'
import Input from 'src/_shared/components/Input'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import SearchIcon from 'src/_shared/components/_icons/SearchIcon'
import {
	BRAND,
	CONTACT_US_BUTTON_LINK,
	CONTACT_US_BUTTON_TEXT,
	CONTACT_US_NUMBER
} from 'src/_shared/constants/env'
import useLanguage from 'src/_shared/hooks/useLanguage'
import { parseMarkdown, sanitiseText } from 'src/_shared/utils/markdown'
import { formatDataTestId } from 'src/_shared/utils/string'
import { useStrapiFaqQuery } from 'src/screens/AccountHelpScreen/queries'

import Collapse from './components/Collapse'
import ContactCard from './components/ContactCard'
import { CONTACT_US_BUTTON_PREFIX_ICON, CONTACT_US_TEXT, SEARCH_INPUT_DEBOUNCE } from './constants'
import { QuestionData, Section } from './types'

/**
 * Replaces placeholders in the given `text` with appropriate values.
 * @param {string} text - The `text` containing placeholders to be replaced.
 * @returns {string} The modified `text` with placeholders replaced.
 */
const replaceContactPlaceholders = (text: string): string => {
	text = text.replace(/{contactUsMethod}/g, CONTACT_US_TEXT ?? '-')
	text = text.replace(/{contactUsNumber}/g, CONTACT_US_NUMBER ?? '-')

	return text
}

const HelpScreen = (): JSX.Element => {
	const intl = useIntl()

	const [searchInputValue, setSearchInputValue] = useState<string>('')

	const locale = useLanguage()

	const { data: sections = [], isLoading: isStrapiFaqQueryLoading } = useStrapiFaqQuery(
		{
			locale,
			brand: BRAND
		},
		{
			select: (data): Section[] => {
				const translationsData = data.attributes.translations

				if (!translationsData) return []

				const sectionsMap: Record<string, QuestionData[]> = {}

				for (const item of translationsData) {
					const categoryName = item.category
					const question = item.question
					// Sanitise the answer text and replace placeholders with contact information (if any).
					const answerWithContactInfo = replaceContactPlaceholders(item.answer)
					const sanitisedAnswer = sanitiseText(answerWithContactInfo)
					const answerElement = parseMarkdown(answerWithContactInfo)

					sectionsMap[categoryName] ??= []
					sectionsMap[categoryName].push({
						question,
						answer: sanitisedAnswer,
						answerElement
					})
				}

				// Flatten the sections map into a single array of sections.
				return Object.entries(sectionsMap).map(([sectionName, questions]) => ({
					sectionName,
					questions
				}))
			}
		}
	)

	const isContactUsCardConfigured =
		!!CONTACT_US_BUTTON_TEXT && !!CONTACT_US_BUTTON_LINK && !!CONTACT_US_BUTTON_PREFIX_ICON

	const filteredSections = useMemo((): Section[] => {
		if (searchInputValue) {
			return (
				sections
					.map(({ questions, ...rest }): Section => {
						return {
							...rest,
							questions: questions.filter(({ question, answer }) => {
								// Check loosely for the search value within the Question and Answer.
								return (
									question.toLowerCase().includes(searchInputValue) ||
									answer.toLowerCase().includes(searchInputValue)
								)
							})
						}
					})
					// Return sections with at least 1 found question.
					.filter(({ questions }): boolean => {
						return questions.length > 0
					})
			)
		}
		return sections
	}, [sections, searchInputValue])

	const noSearchResultsFound = useMemo((): boolean => {
		return searchInputValue.length > 0 && filteredSections.length === 0
	}, [searchInputValue.length, filteredSections.length])

	const handleSearchInputValueChange = debounce(
		((event: ChangeEvent<HTMLInputElement>): void => {
			setSearchInputValue(event.target.value.toLowerCase().trim())
		}) as (...args: unknown[]) => unknown,
		SEARCH_INPUT_DEBOUNCE
	)

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			hideBottomBar
		>
			{isStrapiFaqQueryLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					{/* Search Input */}
					<div className="mb-6 w-full">
						<Input
							dataTestIdPrefix="ahs-search-help"
							placeholder={intl.formatMessage({
								id: 'AccountHelpScreen.InputPlaceholderSearch',
								defaultMessage: 'Search help...'
							})}
							startAdornment={<SearchIcon className="h-6 w-6 text-typography-secondary" />}
							onChange={handleSearchInputValueChange}
						/>
					</div>
					{/* Content */}
					<div
						className={
							isContactUsCardConfigured && noSearchResultsFound
								? 'flex h-full w-full flex-col justify-center'
								: 'w-full'
						}
					>
						{/* Header */}
						{!searchInputValue && (
							<div>
								<h1 className="mb-5 text-typography-primary">
									<FormattedMessage
										id="AccountHelpScreen.TitleFaq"
										defaultMessage="Frequently Asked Questions"
									/>
								</h1>
							</div>
						)}
						{/* Sections */}
						{filteredSections.length > 0 && (
							<div>
								{filteredSections.map(({ sectionName, questions }, sectionIndex): JSX.Element => {
									// Note: Do not use `index` as key here to ensure that components
									// will always be treated as distinct on each render.
									const sectionKey = sectionName.replaceAll(' ', '-').toLowerCase()
									return (
										<div
											key={sectionKey}
											className="border-primary-400/30 [&:not(:last-child)]:mb-5 [&:not(:last-child)]:border-b-[1px]"
										>
											{/* Section Title */}
											<div className="mb-3">
												<p
													data-testid={`ahs-text-section-title-${sectionIndex}`}
													className="body-3-semibold text-typography-primary"
												>
													{sectionName}
												</p>
											</div>
											{/* Question and Answer */}
											{questions.map(
												({ question: title, answerElement }, questionIndex): JSX.Element => {
													const collapseKey = title.replaceAll(' ', '-').toLowerCase()
													return (
														<Collapse
															dataTestIdPrefix={formatDataTestId([
																'ahs-section',
																sectionName.toLowerCase(),
																`question-${questionIndex}`
															])}
															key={collapseKey}
															title={title}
															description={answerElement}
														/>
													)
												}
											)}
										</div>
									)
								})}
							</div>
						)}
						{/* Contact Cards */}
						{isContactUsCardConfigured && !searchInputValue && (
							<ContactCard
								buttonText={CONTACT_US_BUTTON_TEXT}
								buttonLink={CONTACT_US_BUTTON_LINK}
								buttonPrefixIcon={CONTACT_US_BUTTON_PREFIX_ICON}
								className="bg-info-100"
							/>
						)}
						{isContactUsCardConfigured && noSearchResultsFound && (
							<ContactCard
								buttonText={CONTACT_US_BUTTON_TEXT}
								buttonLink={CONTACT_US_BUTTON_LINK}
								buttonPrefixIcon={CONTACT_US_BUTTON_PREFIX_ICON}
								className="mb-16" // Offset to visually appear more vertically center.
							/>
						)}
					</div>
				</>
			)}
		</ScreenContainer>
	)
}

export default HelpScreen

export enum AppMode {
	/**
	 * In this mode:
	 * - The `LandingScreen` is not accessible.
	 * - Users have to login to charge.
	 */
	Default = 'default',
	/**
	 * In this mode:
	 * - The `LandingScreen` is not accessible.
	 * - Users have to login via a Single Sign-On (SSO) Token provided externally via query parameter.
	 */
	Sso = 'sso',
	/**
	 * In this mode:
	 * - Only the `LandingScreen`, `CheckIn` screens, and `ChargingScreen` are accessible.
	 * - Users do not have to login to charge. Hence, the `ChargingScreen` flow will be slightly different.
	 */
	Transient = 'transient'
}

export enum Brand {
	Beep = 'beep',
	Elecca = 'elecca',
	Evfy = 'evfy',
	Evme = 'evme',
	Grab = 'grab',
	Kineta = 'kineta',
	QuantumMobility = 'quantum',
	SpGroup = 'sp',
	Test = 'test',
	TouchNGo = 'tng',
	Tribecar = 'tribecar'
}

export enum Language {
	English = 'en',
	Thai = 'th'
}

import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import Notice from 'src/_shared/components/Notice'
import { MiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import { MiniProgramApi } from 'src/_shared/types/miniProgram'

const MINI_PROGRAM_APPX_URL = 'https://appx/web-view.min.js'

/**
 * Attempts to initialise the Mini Program Appx Script.
 * @see https://beep-technologies.atlassian.net/wiki/x/FQDBDw
 */
const MiniProgramWrapper = ({ children }: PropsWithChildren): ReactNode => {
	const [isMiniProgramError, setIsMiniProgramError] = useState<boolean>(false)

	const [miniProgram, setMiniProgram] = useState<MiniProgramApi | null>(null)

	/**
	 * Initialise Mini Program `appx` Script.
	 */
	useEffect((): (() => void) => {
		const handleAppxScriptError = (event: Event | string): void => {
			console.error('[MiniProgramWrapper] Failed to Load Mini Program Appx Script', event)
			setIsMiniProgramError(true)
		}

		/**
		 * If loaded successfully, save the instance of the Mini Program `my`.
		 */
		const handleAppxScriptLoaded = (): void => {
			if ('my' in window) {
				console.debug('[MiniProgramWrapper] Loaded Mini Program Appx Script')
				setMiniProgram(window.my as MiniProgramApi)
			}
		}

		const appxScript = document.createElement('script')
		console.debug('[MiniProgramWrapper] Attempting to Load Mini Program Appx Script')
		appxScript.src = MINI_PROGRAM_APPX_URL
		appxScript.onerror = handleAppxScriptError
		appxScript.onload = handleAppxScriptLoaded
		document.body.appendChild(appxScript)
		return (): void => {
			document.body.removeChild(appxScript)
		}
	}, [])

	/**
	 * Override `console.debug` and `console.error` so that log
	 * messages can also be emitted into the Mini Program.
	 */
	useEffect((): void => {
		if (miniProgram) {
			// Override `console.debug` and `console.error`
			const consoleDebug = console.debug
			console.debug = (...args): void => {
				miniProgram.postMessage({
					type: 'logDebug',
					data: args
				})
				consoleDebug(args)
			}

			// Override `console.debug` and `console.error`
			const consoleError = console.error
			console.error = (...args): void => {
				miniProgram.postMessage({
					type: 'logError',
					data: args
				})
				consoleError(args)
			}

			miniProgram.postMessage({
				type: 'logDebug',
				data: 'Emitting debug and error log messages to Mini Program'
			})
		}
	}, [miniProgram])

	if (isMiniProgramError) {
		return (
			<div className="mx-auto flex min-h-full max-w-112 flex-col">
				<Notice type="error" header="Oops! Something Went Wrong" />
			</div>
		)
	} else if (!miniProgram) {
		return null
	}
	return (
		<MiniProgramContext.Provider
			value={{
				miniProgram
			}}
		>
			{children}
		</MiniProgramContext.Provider>
	)
}

export default MiniProgramWrapper

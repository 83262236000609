import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import { classNames } from 'src/_shared/utils/elements'

import { FormattedSession } from '../types'
import HistoryItem from './HistoryItem'

interface HistoryListControllerProps {
	formattedChargingSessions: FormattedSession[]
	onFetchNextPageCallback?: () => void
}

const HistoryListController = ({
	formattedChargingSessions,
	onFetchNextPageCallback
}: HistoryListControllerProps) => {
	const navigate = useRouterNavigate()

	const { ref, inView } = useInView({
		threshold: 0.2,
		trackVisibility: true,
		delay: 100
	})
	/* Toggle this literal if you would like the refetch of the next page at a said index
	    e.g. if THRESHOLD_INDEX = 2, the second last index of the page when inview would trigger the next page fetch
		if THRESHOLD_INDEX = 3, the third last index of the page when inview would trigger the next page fetch etc etc
	*/
	const THRESHOLD_INDEX = 3

	useEffect(() => {
		if (inView && onFetchNextPageCallback) {
			onFetchNextPageCallback()
		}
	}, [ref, inView, onFetchNextPageCallback])

	let sessionAccumulator = 0

	const totalSessions = formattedChargingSessions.reduce(
		(acc, group) => acc + group.sessions.length,
		0
	)

	const isLastCardInGroup = (index: number, formatChargingSession: FormattedSession) => {
		return index === formatChargingSession.sessions.length - 1
	}

	return formattedChargingSessions.map((formatChargingSession, groupIndex) => {
		if (groupIndex > 0)
			sessionAccumulator += formattedChargingSessions[groupIndex - 1].sessions.length

		return (
			<div key={groupIndex}>
				{/* display date */}
				<p className="body-1-medium bg-grayscale-200 px-7 py-3 text-typography-secondary">
					{formatChargingSession.key}
				</p>
				<div className="flex flex-col">
					{formatChargingSession.sessions.map((chargingSession, index) => {
						return (
							<button
								data-testid={`hs-hlc-btn-navigate-history-detail-${index}`}
								key={index}
								ref={sessionAccumulator + index === totalSessions - THRESHOLD_INDEX ? ref : null}
								className={classNames(
									`flex w-full flex-col justify-center space-y-1 py-5`,
									!isLastCardInGroup(index, formatChargingSession)
										? 'border-b border-primary-400/30'
										: ''
								)}
								onClick={() => {
									navigate([ScreenRoutePath.HistoryDetail, chargingSession._id ?? ''])
								}}
							>
								<HistoryItem
									dataTestIdPrefix={`hs-hlc-item-${index}`}
									chargingSession={chargingSession}
								/>
							</button>
						)
					})}
				</div>
			</div>
		)
	})
}

export default HistoryListController

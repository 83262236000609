import { PropsWithChildren, ReactNode } from 'react'
import { APP_MODE, BRAND } from 'src/_shared/constants/env'
import { AppMode, Brand } from 'src/_shared/enums/env'

import AppRouter from './router'
import AuthSsoWrapper from './wrappers/AuthSsoWrapper'
import AuthWrapper from './wrappers/AuthWrapper'
import MiniProgramWrapper from './wrappers/MiniProgramWrapper'
import ReactQueryWrapper from './wrappers/ReactQueryWrapper'
import RollbarWrapper from './wrappers/RollbarWrapper'
import StrapiBrandWrapper from './wrappers/StrapiBrandWrapper'
import StrapiTranslationsWrapper from './wrappers/StrapiTranslationsWrapper'

/**
 * Providers and Wrappers that are brand-specific.
 */
const BrandWrappers = ({ children }: PropsWithChildren): ReactNode => {
	switch (BRAND) {
		case Brand.TouchNGo:
			return <MiniProgramWrapper>{children}</MiniProgramWrapper>
		default:
			return children
	}
}

/**
 * Providers and Wrappers that are used in both `default` and `transient` App Modes.
 */
const CommonWrappers = ({ children }: PropsWithChildren): JSX.Element => {
	return (
		<RollbarWrapper>
			<ReactQueryWrapper>
				<BrandWrappers>
					<StrapiBrandWrapper>
						<StrapiTranslationsWrapper>{children}</StrapiTranslationsWrapper>
					</StrapiBrandWrapper>
				</BrandWrappers>
			</ReactQueryWrapper>
		</RollbarWrapper>
	)
}

const App = (): JSX.Element => {
	switch (APP_MODE) {
		case AppMode.Default:
			return (
				<CommonWrappers>
					<AuthWrapper>
						<AppRouter />
					</AuthWrapper>
				</CommonWrappers>
			)
		case AppMode.Transient:
			return (
				<CommonWrappers>
					<AppRouter />
				</CommonWrappers>
			)
		case AppMode.Sso:
			return (
				<CommonWrappers>
					<AuthWrapper>
						<AuthSsoWrapper>
							<AppRouter />
						</AuthSsoWrapper>
					</AuthWrapper>
				</CommonWrappers>
			)
	}
}

export default App

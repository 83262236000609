import { Router } from '@remix-run/router'
import { useMemo } from 'react'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'
import ErrorElement from 'src/App/router/components/ErrorElement'
import { APP_MODE, BRAND } from 'src/_shared/constants/env'
import { AppMode, Brand } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import AccountForgotPasswordScreen from 'src/screens/AccountForgotPasswordScreen'
import AccountHelpScreen from 'src/screens/AccountHelpScreen'
import AccountLoginScreen from 'src/screens/AccountLoginScreen'
import AccountPaymentMethodsNewScreen from 'src/screens/AccountPaymentMethodsNewScreen'
import AccountPaymentMethodsScreen from 'src/screens/AccountPaymentMethodsScreen'
import AccountProfileChangePasswordScreen from 'src/screens/AccountProfileChangePasswordScreen'
import AccountProfileEditScreen from 'src/screens/AccountProfileEditScreen'
import AccountProfileScreen from 'src/screens/AccountProfileScreen'
import AccountRegistrationScreen from 'src/screens/AccountRegistrationScreen'
import AccountScreen from 'src/screens/AccountScreen'
import ChargerScreen from 'src/screens/ChargerScreen'
import CheckInManualScreen from 'src/screens/CheckInManualScreen'
import CheckInQrScannerScreen from 'src/screens/CheckInQrScannerScreen'
import CheckInRedirectScreen from 'src/screens/CheckInRedirectScreen'
import CheckInScreen from 'src/screens/CheckInScreen'
import HistoryDetailScreen from 'src/screens/HistoryDetailScreen'
import HistoryFilterScreen from 'src/screens/HistoryFilterScreen'
import HistoryScreen from 'src/screens/HistoryScreen'
import LandingScreen from 'src/screens/LandingScreen'
import SessionScreen from 'src/screens/SessionScreen'
import TermsAndConditionsScreen from 'src/screens/TermsAndConditionsScreen'
import MapScreen from 'src/screens/_old/map/MapScreen'

import {
	ChargerScreenRoutePath,
	HistoryDetailScreenRoutePath,
	MapSearchScreenRoutePath,
	ScreenRoutePath,
	SessionScreenRoutePath
} from './hooks'

interface Screen {
	path:
		| ScreenRoutePath
		| ChargerScreenRoutePath
		| MapSearchScreenRoutePath
		| SessionScreenRoutePath
		| HistoryDetailScreenRoutePath
		| '*'
	element: JSX.Element
	/**
	 * Specify the `AppMode`(s) where the screen is accessible.
	 */
	appModes: AppMode[]
	/**
	 * User must be logged in to gain access to this route.
	 */
	requiresAuthentication?: boolean
}

const SCREENS: Screen[] = [
	// Public Screens
	{
		path: ScreenRoutePath.Map,
		element: <MapScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: [ScreenRoutePath.Search, ':coordinates'],
		element: <MapScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: ScreenRoutePath.AccountLogin,
		element: <AccountLoginScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: ScreenRoutePath.AccountForgotPassword,
		element: <AccountForgotPasswordScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: ScreenRoutePath.AccountRegistration,
		element: <AccountRegistrationScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: [ScreenRoutePath.Charger, ':cpoEntityCode', ':locationUid', ':evseUid', ':connectorUid'],
		element: <ChargerScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	{
		path: ScreenRoutePath.CheckIn,
		element: <CheckInScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	{
		path: ScreenRoutePath.CheckInManual,
		element: <CheckInManualScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	{
		path: ScreenRoutePath.CheckInQrScanner,
		element: <CheckInQrScannerScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	{
		path: ScreenRoutePath.CheckInRedirect,
		element: <CheckInRedirectScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	{
		path: ScreenRoutePath.Landing,
		element: <LandingScreen />,
		appModes: [AppMode.Transient]
	},
	{
		path: ScreenRoutePath.TermsAndConditions,
		element: <TermsAndConditionsScreen />,
		appModes: [AppMode.Default, AppMode.Sso]
	},
	// Guarded Screens
	{
		path: ScreenRoutePath.Account,
		element: <AccountScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountHelp,
		element: <AccountHelpScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountProfile,
		element: <AccountProfileScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountProfileChangePassword,
		element: <AccountProfileChangePasswordScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountProfileEdit,
		element: <AccountProfileEditScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountPaymentMethods,
		element: <AccountPaymentMethodsScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.AccountPaymentMethodsNew,
		element: <AccountPaymentMethodsNewScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.History,
		element: <HistoryScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: ScreenRoutePath.HistoryFilter,
		element: <HistoryFilterScreen />,
		appModes: [AppMode.Default, AppMode.Sso],
		requiresAuthentication: true
	},
	{
		path: [ScreenRoutePath.Session, ':sessionId'],
		element: <SessionScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	{
		path: [ScreenRoutePath.HistoryDetail, ':sessionId'],
		element: <HistoryDetailScreen />,
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	},
	// Fallback for non-matching routes
	{
		path: '*',
		element: (
			<Navigate
				to={((): ScreenRoutePath => {
					if (APP_MODE === AppMode.Transient) {
						switch (BRAND) {
							case Brand.Kineta:
								return ScreenRoutePath.Landing
							default:
								return ScreenRoutePath.CheckIn
						}
					}
					return ScreenRoutePath.Map
				})()}
			/>
		),
		appModes: [AppMode.Default, AppMode.Sso, AppMode.Transient]
	}
]

const FILTERED_SCREENS: Screen[] = SCREENS.filter(({ appModes }): boolean => {
	return appModes.includes(APP_MODE)
})

const AppRouter = (): JSX.Element => {
	const { isAuthenticated } = useAuthContext()

	const router = useMemo((): Router => {
		return createBrowserRouter(
			FILTERED_SCREENS.map(
				({ path, element: screenElement, requiresAuthentication }): RouteObject => {
					const formattedPath = Array.isArray(path) ? path.join('/') : path
					const element =
						requiresAuthentication && !isAuthenticated ? (
							<Navigate
								to={{
									pathname: ScreenRoutePath.AccountLogin,
									search: `redirect=${formattedPath}`
								}}
								replace={true}
							/>
						) : (
							screenElement
						)
					return {
						path: formattedPath,
						element,
						errorElement: <ErrorElement />
					}
				}
			)
		)
	}, [isAuthenticated])

	return <RouterProvider router={router} />
}

export default AppRouter

import { ReactNode, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Skeleton from 'src/_shared/components/Skeleton'
import LocationPinFilledIcon from 'src/_shared/components/_icons/LocationPinFilledIcon'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

export interface DetailsGridItem {
	labelKey: string
	label: ReactNode
	/**
	 * Primarily used for the Tariff Information tooltip `button`.
	 */
	labelSuffix?: ReactNode
	value: ReactNode
	/**
	 * The clickable area is the entire `div` that wraps the label and value.
	 */
	onClick?: () => void
}

interface DetailsGridProps {
	className?: string
	location: OmniLocation | null
	/**
	 * If `itemList.length <= 6`, there will only be 2 columns. Else, it will have 3 columns.
	 */
	itemList: DetailsGridItem[]
	loading?: boolean
}

const GRID_LENGTH_THRESHOLD = 5

const DetailsGrid = ({ className, location, itemList, loading }: DetailsGridProps): JSX.Element => {
	const isTwoColGrid = itemList.length <= GRID_LENGTH_THRESHOLD

	const { address } = location ?? {}

	return (
		<div
			className={classNames(
				'grid w-full gap-2 rounded-lg border border-primary-400/30 p-3',
				// Use `grid-cols-6` for 3-column grid so that we can divide the last row's item columns up by 2 or 3.
				isTwoColGrid ? 'grid-cols-2' : 'grid-cols-2 2xs:grid-cols-6',
				className
			)}
		>
			{/* Location Header Row */}
			<div
				className={classNames(
					// Base Classes
					'flex min-h-[54px] flex-row space-x-2 rounded-lg bg-grayscale-300/80 px-3 py-2',
					// Header Row Column Span
					isTwoColGrid ? 'col-span-2' : 'col-span-2 2xs:col-span-6'
				)}
			>
				<Skeleton loading={loading}>
					<LocationPinFilledIcon className="h-4 min-w-4 text-primary-600" />
					<div className="flex min-w-0 flex-grow flex-col">
						<div className="mb-0.5">
							<p className="caption-3-normal truncate text-typography-secondary/60 ">
								<FormattedMessage
									id="useChargerItemLists.LabelLocation"
									defaultMessage="Location"
								/>
							</p>
						</div>
						<div>
							<p
								data-testid="cs-dg-text-address"
								className="body-2-semibold truncate text-typography-primary"
							>
								{address ? address : '-'}
							</p>
						</div>
					</div>
				</Skeleton>
			</div>
			{/* Grid Items */}
			{itemList.map(
				(
					{ label, labelKey, labelSuffix, value, onClick: handleLabelValueClick },
					index
				): JSX.Element => {
					return (
						<div
							data-testid={formatDataTestId(['cs-dg-btn-click-label-value', labelKey])}
							className={classNames(
								// Base Classes
								'group flex min-h-[54px] flex-col rounded-lg bg-grayscale-300/80 px-3 py-2',
								// Click Handler Class
								handleLabelValueClick ? 'cursor-pointer' : null,
								// Item Column Span
								((): string | null => {
									if (isTwoColGrid) {
										// Last odd-numbered item spans across 2 columns.
										if (itemList.length % 2 === 1 && index === itemList.length - 1) {
											return 'col-span-2'
										}
										return 'col-span-1'
									} else {
										// Last odd-numbered item spans across 3 columns.
										if (itemList.length % 3 === 1 && index === itemList.length - 1) {
											return 'col-span-2 2xs:col-span-6'
										}
										// Last 2 items span across 1.5 columns.
										else if (itemList.length % 3 === 2 && index >= itemList.length - 2) {
											return 'col-span-1 2xs:col-span-3'
										}
										return 'col-span-1 2xs:col-span-2'
									}
								})()
							)}
							key={index}
							onClick={handleLabelValueClick}
						>
							<Skeleton loading={loading}>
								<div className="mb-0.5 flex flex-row items-center space-x-1 text-typography-secondary/60">
									<p
										data-testid={formatDataTestId(['cs-dg-text-label', labelKey])}
										className="caption-3-normal truncate"
									>
										{label}
									</p>
									{labelSuffix}
								</div>
								<div>
									<p
										data-testid={formatDataTestId(['cs-dg-text-value', labelKey])}
										className="body-2-semibold truncate text-typography-primary"
									>
										{value}
									</p>
								</div>
							</Skeleton>
						</div>
					)
				}
			)}
		</div>
	)
}

const MemoisedDetailsGrid = memo(DetailsGrid)

export default MemoisedDetailsGrid
